import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import gymPic from "../assets/gymPic.jpeg";
import gymPic2 from "../assets/gymPic2.avif";

const GymSoftware = () => {
  return (
    <Container className="my-2">
      <Row
        style={{
          backgroundColor: "#0a5a6a",
        }}
      >
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          Zpotter Technologies
        </h2>
      </Row>
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={gymPic} alt="1sAndOs" fluid />
        </Col>{" "}
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "0.95rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>
            {" "}
            Revolutionize Your Gym Management with Our All-in-One Software
            Solution
          </h2>
          <p style={{ textIndent: "3rem" }}>
            Revolutionize Your Gym Management with Our All-in-One Software
            Solution Our gym management software is designed to streamline every
            aspect of running a gym, making your operations more efficient and
            your members more engaged. With features like Mobile Attendance and
            Member Registration using QR codes, you can easily manage member
            check-ins and registrations without any hassle. Key features include
            Attendance Management, Member and Visitor Tracking, and Payments &
            Finance Management, allowing you to stay organized and up-to-date
            with every member’s activity and financial status. Our software
            supports multiple admins, making it perfect for gyms with multiple
            branches or management teams. Stay ahead with Daily, Weekly, and
            Monthly Analytics, providing insights into gym trends and member
            engagement. Generate and download detailed reports, and even send
            Member Invoices directly from the software. Plus, with Automatic
            WhatsApp Notifications, you can send reminders, promotions, and
            updates to members with ease. Our software is available on multiple
            devices, including Android mobile apps, so you can manage your gym
            on the go. You also get access to multiple themes to customize the
            look and feel of the software. Best of all, our software is
            available many languages like English, Tamil, Telugu, Kannada,
            Malayalam and Hindi, making it accessible to a wider audience.
          </p>
        </Col>{" "}
      </Row>{" "}
      <Row className="align-items-center">
        <h2
          style={{
            color: "#0a5a6a",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {" "}
          Available Features
        </h2>
        <div
          style={{
            height: "100vh",
            backgroundColor: "#ecf3f2",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${gymPic2})`, // Dims the background image
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <ul
            style={{
              color: "Yellow",
              display: "flex", // Apply flexbox
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically (optional)
              flexDirection: "column", // Align list items vertically
              listStyle: "none", // Remove bullets
              padding: "30px", // Optional: to remove default padding
            }}
          >
            <li>Mobile Attendance using QR code</li>
            <li>Member Registration using QR code</li>
            <li>Attendance Management</li>
            <li>Member Management</li>
            <li>Member Profile Picture</li>
            <li>Gym Visitors Tracker</li>
            <li>Member Payments Tracker</li>
            <li>Gym Equipments Management</li>
            <li>Accounts & Finance Management</li>
            <li>Multiple Gym Admins</li>
            <li>Daily, Weekly, Monthly Analytics</li>
            <li>Daily Trends</li>

            <li>Reports Download </li>
            <li>Member Invoice Download</li>
            <li>Multiple Software Themes</li>
            <li>Use in Multiple Devices - Laptop and Mobiles</li>
            <li>Android Mobile App</li>
            <li>Automatic WhatsApp Notifications to Members</li>
            <li>Automatic WhatsApp Reminders to Members</li>
            <li>Send WhatsApp Promotions</li>
            <li>Software is Available in English and Tamil Languages also</li>
          </ul>
        </div>
      </Row>{" "}
    </Container>
  );
};

export default GymSoftware;
