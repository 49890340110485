import React from "react";
import { createRoot } from "react-dom/client";
//import './index.css';
//import 'bootstrap/dist/css/bootstrap.min.css'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import VisitorsScreen from "./screens/ContactScreen";
import AboutScreen from "./screens/AboutScreen";
import ServicesScreen from "./screens/ServicesScreen";
import ContactScreen from "./screens/ContactScreen";
import HomeScreen from "./screens/HomeScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import GymSoftware from "./screens/GymSoftware";
import HousingSoftware from "./screens/HousingSoftware";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/home" element={<HomeScreen />} />
      <Route path="/about" element={<AboutScreen />} />
      <Route path="/services" element={<ServicesScreen />} />
      <Route path="/gymSoftware" element={<GymSoftware />} />
      <Route path="/housingSoftware" element={<HousingSoftware />} />
      <Route path="/contact" element={<ContactScreen />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicyScreen />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    </Route>
  )
);

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
